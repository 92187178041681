<template>
  <div class="wrap">
    <div class="top_bar">
      <img
        src="@/assets/images/draw/treasureBowl.png"
        class="left_bar"
        alt=""
      />
      <div class="right_bar">
        <div class="money">{{ $money(money, false) }}</div>
        <div class="line"></div>
        <div class="title">Nổ hũ thắng lớn</div>
      </div>
    </div>
    <div class="marquee_wrap">
      <Icon name="volume" size="33px" color="#ff8a00" class="icon" />
      <marquee
        behavior="scroll"
        direction="up"
        scrollamount="3"
        class="marquee"
      >
        <div class="halloffame">
          <div class="notice-item-wrap">
            <span class="spancolor">Chúc mừng&nbsp;906****658</span>&nbsp;đã
            trúng thưởng&nbsp;
            <span class="money"> Mất điểm </span>
          </div>
        </div>
      </marquee>
    </div>
    <div class="turntable">
      <canvas id="win_wrap" :width="width" :height="height"></canvas>
      <div class="bg">
        <div class="btn" @click="handleClick">Bắt</div>
        <div class="draw_num">Số lượt quay thưởng: {{ drawsTimes }} lần</div>
      </div>
    </div>
    <div class="records_wrap">
      <div class="title">--------Lịch sử trúng thưởng---------</div>
      <div class="list" v-if="records.length">
        <div
          class="list_item"
          v-for="(item, index) in records"
          :key="item.id || index"
        >
          <div class="content">Chúc mừng đã trúng thưởng {{ item.title }}</div>
          <div class="time">{{ $time(item.indate, "yy-MM-dd") }}</div>
        </div>
      </div>
      <div class="none" v-else>Tạm thời chưa có lịch sử trúng thưởng</div>
    </div>
    <div class="desc">
      <div v-html="rule"></div>
    </div>
  </div>
</template>

<script>
import { Icon, Dialog, Toast } from "vant";
import timeSynchronization from "@/utils/timeSynchronization";
import dataDetail from "@/utils/dataDetail";
import winWheel from "@/utils/Winwheel";
import { $get, $post } from "@/utils/request";
import { TOKEN_NAME } from "@/store/index";
const timer = new timeSynchronization({ time: new Date() });
export default {
  components: { Icon },
  data() {
    return {
      money: 0,
      width: 0,
      height: 0,
      rule: "",
      canvasWrap: null,
      loading: false,
      drawsTimes: 0,
      records: [],
      winMsg: "",
    };
  },
  created() {
    timer.start();
    timer.sleep(() => {
      this.money = 350000000 + dataDetail.rand(0, 100000000) - 50000000;
    }, 1);
    const token = localStorage.getItem(TOKEN_NAME);
    if (!token) {
      this.toLogin();
      return;
    }
    this.GetSetting();
  },
  mounted() {
    this.width = screen.width;
    this.height = (this.width * 300) / 390;
    const _this = this;
    const radiusSize = this.height / 2;
    this.canvasWrap = new winWheel({
      canvasId: "win_wrap",
      numSegments: 0,
      segments: [],
      outerRadius: radiusSize,
      rotationAngle: 0,
      textFontWeight: 500,
      textFontSize: 12,
      textFillStyle: "#fff",
      textDirection: "reversed",
      centerX: (this.width * 195) / 390,
      centerY: radiusSize,
      animation: {
        type: "spinToStop",
        duration: 5,
        spins: 8,
        callbackFinished: _this.stop,
      },
    });
  },
  methods: {
    async GetSetting() {
      const {
        data: { ret, msg, data },
      } = await $get("/marketing/xydzp");
      if (ret == -1) {
        this.toLogin();
        return;
      }
      if (ret == 1) {
        const { odds, userLists, num, rule } = data;
        this.drawsTimes = num;
        this.records = userLists || [];
        this.rule = rule;
        const _odds = odds.reverse();
        for (let i = 0, len = odds.length; i < len; i++) {
          const item = _odds[i];
          this.canvasWrap.addSegment(
            {
              text: item.title,
              fillStyle: item.color,
            },
            1
          );
        }
        this.canvasWrap.numSegments = odds.length;
        this.canvasWrap.draw();
      }
    },
    toLogin() {
      this.dialog(
        "Bạn chưa đăng nhập, vui lòng bấm để xác nhận đăng nhập!",
        this.$router.replace("/login")
      );
    },
    dialog(msg, callback = () => {}) {
      Dialog.confirm({
        message: msg,
        confirmButtonText: "xác nhận",
        showCancelButton: false,
      }).then(() => {
        callback();
      });
    },
    async handleClick() {
      if (this.loading) return;
      this.addLoading();
      try {
        const {
          data: { ret, msg, data },
        } = await $post("/marketing/xydzp", { act: "start" });
        this.clearLoading();
        if (ret == 1) {
          this.winMsg = msg;
          this.action(data.rotate);
          return;
        }
        Toast(msg);
      } catch (error) {
        console.log("抽奖接口出错:", error);
        this.clearLoading();
      }
    },
    addLoading() {
      this.loading = true;
      Toast.loading({ duration: 0 });
    },
    clearLoading() {
      this.loading = false;
      Toast.clear();
    },
    action(angle) {
      if (!!this.canvasWrap.rotationAngle) {
        this.canvasWrap.rotationAngle = 0;
        this.canvasWrap.draw();
      }
      this.canvasWrap.animation.stopAngle = angle || 0;
      this.canvasWrap.startAnimation();
    },
    stop(e) {
      console.log(1);
      this.dialog(this.winMsg);
    },
  },
  beforeDestroy() {
    timer.clear();
  },
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background: linear-gradient(#008897, #002d48);
  overflow: hidden;

  .top_bar {
    margin-top: 88px;
    padding-left: 44px;
    display: flex;

    .left_bar {
      width: 147px;
      height: 221px;
    }

    .right_bar {
      margin-left: 36px;
      padding-top: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 450px;

      .money {
        color: #ffdc51;
        font-size: 72px;
        font-family: Avenir-Roman;
      }

      .line {
        width: 450px;
        height: 4px;
        background: #ff8a00;
      }

      .title {
        font-family: TeXGyreAdventor-Regular;
        font-size: 33px;
        margin-top: 10px;
        color: #ffdc51;
      }
    }
  }

  .marquee_wrap {
    margin: 0 25px;
    display: flex;
    background-color: #000f42;
    -webkit-box-align: center;
    align-items: center;
    padding: 11px 14px;
    border-radius: 99px;
    .icon {
      margin-right: 14px;
    }
    .marquee {
      height: 33px;
      .halloffame {
        font-size: 21px;
        line-height: 3em;
        .notice-item-wrap {
          color: #fec500;
          // font-size: ;
          .money {
            color: #00fee9;
          }
        }
      }
    }
  }
  .turntable {
    margin-top: 50px;
    position: relative;
    padding-top: 164px;
    padding-bottom: 180px;
    .bg {
      width: 820px;
      height: 954px;
      background: url("../../assets/images/draw/turntable_bg.png");
      background-size: 100% 100%;
      margin-left: 10px;
      position: absolute;
      top: -20px;
      left: -50px;
      .btn {
        width: 280px;
        line-height: 80px;
        font-family: TeXGyreAdventor-Regular;
        font-size: 67px;
        font-weight: 700;
        font-stretch: normal;
        letter-spacing: 13px;
        color: #fffc00;
        text-align: center;
        position: absolute;
        bottom: 160px;
        left: 50%;
        margin-left: 10px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        &:active {
          opacity: 0.8;
        }
      }
      .draw_num {
        color: #fff;
        font-size: 30px;
        font-family: AdobeHeitiStd-Regular;
        position: absolute;
        width: -webkit-max-content;
        width: max-content;
        bottom: 40px;
        line-height: 70px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
  }
  .records_wrap {
    width: 659px;
    background-color: #003e60;
    border-radius: 10px;
    margin: 26px auto 0;
    padding: 32px 21px;
    .title {
      font-family: AdobeHeitiStd-Regular;
      font-size: 33px;
      font-weight: 700;
      font-stretch: normal;
      letter-spacing: 0;
      color: #fec500;
      text-align: center;
    }
    .list {
      height: 160px;
      margin-top: 39px;
      overflow-y: auto;
      .list_item {
        &:first-child {
          margin-top: 0;
        }
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        margin-top: 40px;
        .content {
          max-width: 76%;
          font-family: TeXGyreAdventor-Regular;
          font-size: 25px;
          font-stretch: normal;
          letter-spacing: 0;
          color: #fff;
        }
        .time {
          font-family: TeXGyreAdventor-Regular;
          font-size: 21px;
          font-stretch: normal;
          letter-spacing: 0;
          color: #00ff9c;
        }
      }
    }
    .none {
      font-size: 33px;
      color: #fff;
      text-align: center;
      margin-top: 39px;
      line-height: 160px;
    }
  }
  .desc {
    width: 701px;
    margin: 30px auto 0;
    font-family: TeXGyreAdventor-Regular;
    font-size: 27px;
    font-stretch: normal;
    letter-spacing: 0;
    color: #fec500;
    padding-bottom: 50px;
    margin-bottom: 100px;
  }
}
</style>